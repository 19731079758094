<template>
    <div>
        <portal to="toolbar">
            <v-tabs
                class="gray-tabs toolbar-tabs" slider-color="orange" background-color="blue-grey lighten-5"
                v-model="tab">

                <v-tab to="#country">{{ $t(`tabs['countries']`) }}</v-tab>
                <v-tab to="#city">{{ $t(`tabs['cities']`) }}</v-tab>
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">
            <v-tab-item id="country" :transition="false" :reverse-transition="false">
                <v-container fluid>
                    <countries-directory/>
                </v-container>
            </v-tab-item>
            <v-tab-item id="city" :transition="false" :reverse-transition="false">
                <v-container fluid>
                    <cities-directory/>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import CountriesDirectory from "../components/directories/CountriesDirectory"
import CitiesDirectory from "../components/directories/CitiesDirectory"

export default {
    components: { CountriesDirectory, CitiesDirectory },
    head() {
        return {
            title: this.$t('titles.directory'),
            toolbar: this.$t('toolbar.directory'),
        }
    },
    fetch({ store, redirect }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }
    },
    data() {
        return {
            tab: this.$route.hash || "country",
        }
    },
}
</script>
<style>
    .directory-map {
        height: 500px;
        width: 100%;
    }
</style>
