<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-form ref="form" lazy-validation v-model="isValid" @submit.prevent="onSubmit()">
                <v-card-title>
                    <span class="headline">
                        <slot name="title">{{ $t(`actions['add country']`) }}</slot>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <header class="body-1 mb-4">{{ $t(`labels['country name']`) }}</header>
                                <compound-input
                                    :rules="getCompoundInputRules"
                                    ref="name"
                                    v-model="model.Name"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn outlined ripple color="blue-grey darken-1" type="submit">{{ $t("actions.save") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import CompoundInput from "../CompoundInput"
import { DEFAULT_DIRECTORY } from "../../util/models"

const isEqual = (value, other) => JSON.stringify(value) === JSON.stringify(other)

export default {
    components: { CompoundInput },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            model: Object.assign({}, DEFAULT_DIRECTORY(), this.value),
            isValid: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (!isEqual(this.model, val)) {
                    this.model = Object.assign({}, DEFAULT_DIRECTORY(), val)
                }
            },
        },

        model() {
            if (!isEqual(this.model, this.value)) {
                this.$emit("input", this.model)
            }
        },

        visible: {
            immediate: true,
            handler(val) {
                !val && this.reset()
            },
        },
    },
    computed: {
        getCompoundInputRules() {
            return [
                (v, key) => {
                    if (key !== "RU") {
                        return true
                    }

                    return !!(v || "").trim() || this.$t(`errors['field required']`)
                },
            ]
        },
    },
    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.$emit("submit", this.model)
                this.onClose()
            }
        },

        onClose() {
            this.$emit("close")
        },

        reset() {
            this.$nextTick(() => this.$refs.form && this.$refs.form.reset())
        },
    },
}
</script>
