<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th>{{ $t("tables.title") }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in getList" :key="item.ID">
                    <td>{{ item.displayName }}</td>
                    <td class="text-right">
                        <v-btn text icon color="blue-grey"
                               @click="onClickEdit(item.ID)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn text icon color="blue-grey"
                               @click="onClickDelete(item.ID)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import * as Format from "../../util/format"

export default {
    props: {
        items: {
            type: Array,
            default: () => ([]),
        },
    },
    computed: {
        getList() {
            return this.items.map(item => {
                if (item.displayName) {
                    return item
                }
                return Object.assign({ displayName: Format.getName(item.Name) }, item)
            })
        },
    },
    methods: {
        onClickEdit(id) {
            this.$emit("edit", id)
        },

        onClickDelete(id) {
            this.$emit("delete", id)
        },
    },
}
</script>

<style>
    .directory-table__edit {
        cursor: pointer;
    }
</style>
