<template>
    <v-container fluid style="background-color: #fff">
        <v-row align="center">
            <v-col cols="6">
                <h2 :class="{'title': $vuetify.breakpoint.smOnly, 'subtitle-1': $vuetify.breakpoint.xsOnly}">{{
                    $t(`headers['countries list']`) }}</h2>
            </v-col>
            <v-col cols="6" align="end">
                <v-btn type="submit" text ripple color="primary" :x-small="$vuetify.breakpoint.xsOnly"
                       @click="onClickAdd()">
                    <v-icon left>mdi-plus</v-icon>
                    {{ $t(`actions['add']`) }}
                </v-btn>
            </v-col>
        </v-row>

        <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate
            ></v-progress-circular>
        </div>

        <directory-table v-show="!loading" :items="countries" @delete="onDeleteCountry" @edit="onEditCountry"/>
        <add-country-modal :visible="visibleModal" v-model="upsertCountry" @submit="onSubmitCountry"
                           @close="closeModal()"/>
    </v-container>
</template>

<script>
import { mapActions } from "vuex"
import AddCountryModal from "./AddCountryModal"
import DirectoryTable from "./DirectoryTable"
import * as Format from "../../util/format"

export default {
    components: { AddCountryModal, DirectoryTable },
    data() {
        return {
            loading: false,
            visibleModal: false,
            countries: [],
            upsertCountry: null,
        }
    },

    created() {
        this.updateList()
    },

    methods: {
        ...mapActions("directory", ["getCountries", "createCountry", "updateCountry", "deleteCountry"]),
        upsert(country) {
            return "ID" in country ? this.updateCountry(country) : this.createCountry(country)
        },

        delete(id) {
            this.loading = true
            return this.deleteCountry(id)
                .finally(() => this.updateList())
        },

        updateList() {
            this.loading = true
            this.getCountries().then((countries) => {
                this.countries = countries
                this.loading = false
            })
        },

        onClickAdd() {
            this.upsertCountry = null
            this.openModal()
        },

        onSubmitCountry(country) {
            this.loading = true
            this.closeModal()
            this.upsert(country)
                .finally(() => this.updateList())
        },

        onEditCountry(id) {
            this.upsertCountry = this.countries.find(c => c.ID === id)
            this.openModal()
        },

        onDeleteCountry(id) {
            const country = this.countries.find(c => c.ID === id)

            this.$dialog.confirm({
                title: this.$t(`rudiments['sure']`),
                text: this.$t(`messages['remove country']`, [Format.getName(country.Name)]),
            }).then(result => result && this.delete(id))
        },

        closeModal() {
            this.visibleModal = false
        },

        openModal() {
            this.visibleModal = true
        },
    },
}
</script>
