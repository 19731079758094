<template>
    <v-container fluid style="background-color: #fff">
        <v-row>
            <v-col cols="12" lg="3">
                <h2 :class="{'title': $vuetify.breakpoint.smAndDown}">{{ $t(`headers['cities list']`) }}</h2>
            </v-col>

            <v-col cols="12" lg="9" class="py-0" align="end">
                <v-row dense align="center">
                    <v-col cols="12" md="9" class="py-0">
                        <country-autocomplete v-model="country"/>
                    </v-col>
                    <v-col cols="12" md="3" class="py-3">
                        <v-btn type="submit" text ripple color="primary" @click="onClickAdd()">
                            <v-icon left>mdi-plus</v-icon>
                            {{ $t(`actions['add']`) }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate
            ></v-progress-circular>
        </div>

        <add-city-modal :visible="visibleModal"
                        v-model="upsertCity"
                        @submit="onSubmitCity"
                        @close="closeModal()"/>

        <directory-table v-show="!loading" :items="getCitiesList" @edit="onEditCity" @delete="onClickDelete"/>
    </v-container>
</template>

<script>
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import DirectoryTable from "./DirectoryTable"
import AddCityModal from "./AddCityModal"
import CountryAutocomplete from "./CountryAutocomplete"
import unobserve from "../../util/unobserve"

export default {
    components: { CountryAutocomplete, DirectoryTable, AddCityModal },

    data() {
        return {
            country: null,
            visibleModal: false,
            loading: false,
            upsertCity: null,
            cities: [],
        }
    },

    computed: {
        getCitiesList() {
            return this.cities.map(city => Object.assign(city, {
                displayName: `${Format.getName(city.Name)}, ${Format.getName(city.Country.Name)}`,
            }))
        },
    },

    watch: {
        country() {
            this.updateList()
        },
    },

    created() {
        this.updateList()
    },

    methods: {
        ...mapActions("directory", ["getCities", "updateCity", "createCity", "deleteCity"]),

        getFilter() {
            if (!this.country) {
                return {}
            }

            return { CountryID: this.country.ID }
        },

        updateList() {
            this.loading = true
            this.getCities(this.getFilter())
                .then(list => this.cities = list)
                .catch(e => {
                    // eslint-disable-next-line no-console
                    console.log("error", e)
                })
                .finally(() => this.loading = false)
        },

        onClickAdd() {
            this.openModal()
            this.upsertCity = { Country: this.country }
        },

        onSubmitCity(city) {
            this.loading = true
            this.upsert(unobserve(city))
                .finally(() => this.updateList())
        },

        onEditCity(cityId) {
            this.openModal()
            this.upsertCity = this.cities.find(c => c.ID === cityId)
        },

        onClickDelete(cityId) {
            const city = this.cities.find(c => c.ID === cityId)

            this.$dialog.confirm({
                title: this.$t(`rudiments['sure']`),
                text: this.$t(`messages['remove city']`, [Format.getName(city.Name)]),
            }).then(result => result && this.onConfirmDelete(cityId))
        },

        onConfirmDelete(id) {
            this.loading = true
            this.deleteCity(id)
                .finally(() => this.updateList())
        },

        closeModal() {
            this.visibleModal = false
        },

        openModal() {
            this.visibleModal = true
        },

        upsert(city) {
            city.CountryID = city.Country.ID
            if (city.ID) {
                return this.updateCity(city)
            }
            return this.createCity(city)
        },
    },
}
</script>
