<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose country']`)"
                      :rules="rules"
                      :items="countries"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="countryToOption"
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import unobserve from "@/util/unobserve"
import Autocomplete from "../Autocomplete"

export default {
    components: { Autocomplete },
    props: {
        value: [Array, Object],
        multiple: Boolean,
        rules: Array,
    },
    data() {
        return {
            model: !this.value ? this.value : unobserve(this.value),
            countries: [],
            isLoading: false,
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            this.model = this.value
        },
    },
    methods: {
        ...mapActions("directory", ["findCountryByQuery"]),
        countryToOption(country) {
            return { id: country.ID, name: Format.getName(country.Name) }
        },

        onChangeQuery(query) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            this.findCountryByQuery({ query }).then(countries => {
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.countries = countries
                }
            })
        },
    },
}
</script>
