<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-form ref="form" lazy-validation v-model="isValid" @submit.prevent="onSubmit()">
                <v-card-title>
                    <span class="headline">
                        <slot name="title">{{ $t(`actions['add city']`) }}</slot>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row no-gutters class="mb-6">
                            <v-col cols="12">
                                <country-autocomplete :rules="requiredCountry" v-model="model.Country"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-6">
                            <v-col cols="12">
                                <header class="body-1 mb-4">{{ $t(`labels['city name']`) }}</header>
                                <compound-input
                                    :rules="getCompoundInputRules"
                                    ref="name"
                                    v-model="model.Name"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <header class="mb-2">
                                    {{ $t(`labels['coordinates']`) }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" small>mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>{{ $t(`labels['city map']`) }}</span>
                                    </v-tooltip>
                                </header>
                                <map-location-control
                                    ref="map"
                                    class="city-modal__map"
                                    :rules="[requiredLocation]"
                                    as-model
                                    v-model="model.Location"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn outlined ripple color="blue-grey darken-1" type="submit">{{ $t("actions.save") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import CompoundInput from "../CompoundInput"
import CountryAutocomplete from "./CountryAutocomplete"
import MapLocationControl from "../MapLocationControl"
import { DEFAULT_DIRECTORY } from "../../util/models"

const isEqual = (value, other) => JSON.stringify(value) === JSON.stringify(other)

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { CompoundInput, CountryAutocomplete, MapLocationControl },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            model: Object.assign({}, DEFAULT_DIRECTORY(), this.value),
            isValid: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (!isEqual(this.model, val)) {
                    this.model = Object.assign({}, DEFAULT_DIRECTORY(), val)
                }
            },
        },

        model() {
            if (!isEqual(this.model, this.value)) {
                this.$emit("input", this.model)
            }
        },

        visible: {
            immediate: true,
            handler(val) {
                !val && this.reset()
            },
        },
    },
    computed: {
        getCompoundInputRules() {
            return [
                (v, key) => {
                    if (key !== "RU") {
                        return true
                    }

                    return !!(v || "").trim() || this.$t(`errors['field required']`)
                },
            ]

        },
        requiredCountry() {
            return [v => !!v || this.$t(`errors['country required']`)]
        },
        requiredLocation() {
            return v => !v || !v.Lat || !v.Lon ? this.$t(`errors.coordinates`) : true
        },
    },
    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.$emit("submit", this.model)
                this.onClose()
            }
        },

        onClose() {
            this.$emit("close")
        },

        reset() {
            this.$nextTick(() => this.$refs.form && this.$refs.form.reset())
        },
    },
}
</script>

<style>
    .city-modal__map {
        height: 300px;
    }
</style>
